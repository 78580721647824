
import React from "react";
import BaseLayout from "../components/BaseLayout"
const About = () => {
    return (
      <BaseLayout>
      <div className="text-lg">
       <h1>About</h1>
       <div>
         Software Architect and Engineer documenting bits and pieces of a web3 learning journey
         </div>
       </div>
      </BaseLayout>
    )
  }
  
  export default About